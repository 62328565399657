import React from "react"
import { Link } from "gatsby"

import { getCxFromStyles } from "../../helpers"
import Button from "../Button"

import * as styles from "./Details.module.scss"

const Details = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root", "row")}>
      <div className={cx("container", "left_container")}>
        <div className={cx("container_inner")}>
          <span className={cx("title", "blue")}>HOTELS</span>
          <h3 className={cx("header")}>
            Enhanced Revenue & Operational Efficiency
          </h3>
          <p>
            <strong>Rate Cleaner</strong> simplifies rate management, swiftly
            identifying errors and streamlining corrections to ensure your
            hotel's rates are competitive and accurate.
          </p>
          <ul>
            <li>Immediate error detection in rate descriptions</li>
            <li>Reduced costs from fewer booking discrepancies</li>
            <li>Increased direct sales through optimized GDS channel</li>
          </ul>
          <Link to="/hotels">
            <Button button="primary_extra" color="blue" onClick={() => {}}>
              Info for Hotels
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={cx("container", "right_container", "background_container")}
      >
        <div className={cx("container_inner")}>
          <span className={cx("title", "green")}>TRAVEL AGENCIES</span>
          <h3 className={cx("header")}>
            Streamlined Bookings with Accurate Data
          </h3>
          <p>
            <strong>Rate Cleaner</strong> provides travel agencies with the
            precision tools needed to ensure the accuracy of rate information,
            building trust and efficiency in the booking process.
          </p>
          <ul>
            <li>Rigorous verification of hotel rate accuracy</li>
            <li>Strengthened trust from reliable rate presentation</li>
            <li>Efficient communication with hotels for rate adjustments</li>
          </ul>
          <Link to="/travel-agencies">
            <Button button="primary_extra" color="green" onClick={() => {}}>
              Info for Travel Agencies
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Details
