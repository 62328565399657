import React from "react"

import { getCxFromStyles } from "../../helpers"

import * as styles from "./Partner.module.scss"
import reasonChart from "../../assets/reason-chart.png"

const Partner: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <>
      <div className={cx("image")}>
        <img src={reasonChart} alt="hotel" />
      </div>
      <div className={cx("root")}>
        <div className={cx("content")}>
          <h2>
            Reasons to
            <br /> partner with us
          </h2>
          <ul>
            <li>Resolve issues faster</li>
            <li>Reduce operational costs</li>
            <li>Unlock revenue potential</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Partner
