import React from "react"

import { getCxFromStyles } from "../../helpers"

import * as styles from "../../pages/pages.module.scss"

const CookiesPolicy: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h1>Cookies Policy</h1>
      <section>
        <h2>I. DEFINITIONS</h2>
        <ol>
          <li>
            <strong>Administrator</strong> - a company under the name of TRAVEL
            MINDS spółka z ograniczoną odpowiedzialnością (a limited liability
            company) with its registered office in Wrocław (53-025), ul.
            Skarbowców 23A, entered into the register of entrepreneurs kept by
            the District Court for Wrocław-Fabryczna in Wrocław, VI Commercial
            Division of the National Register of Entrepreneurs under the number
            KRS 0000867363, NIP: 8992888262, REGON: 387412180, share capital
            5.000,00 PLN.
          </li>
          <li>
            <strong>Cookies</strong> - IT data, small text files, saved by
            visited websites and stored on the Devices through which you use the
            Administrator's Website. The information contained in Cookies is
            necessary for the Website to function properly, and these files are
            encrypted, which prevents the sharing of files with unauthorized
            persons. The information collected in Cookies can be read by the
            Administrator and his contractors (for technical reasons).
          </li>
          <li>
            <strong>Device</strong> - an electronic device through which access
            to the Administrator's Website is obtained.
          </li>
          <li>
            <strong>Website</strong> - www.ratecleaner.com with subpages and
            subdomains, in particular with the app.ratecleaner.com domain.
          </li>
        </ol>
      </section>
      <section>
        <h2>II. USE OF COOKIES</h2>
        <ol>
          <li>The Administrator uses Cookies via the Website.</li>
          <li>
            The information collected on the basis of Cookies are used for the
            purposes of proper optimization of the operation of the Website, as
            well as for statistical and advertising purposes.
          </li>
          <li>
            Cookies record the activity of the Website User, recognizing the
            Device, thanks to which the Website is displayed in a manner
            optimized to the individual preferences of the User.
          </li>
          <li>
            The solutions used in the Website are safe for Users' Devices, using
            the Administrator's Website. It is not possible for dangerous or
            malicious software to enter Users' Devices.
          </li>
          <li>
            The Administrator uses two types of Cookies:
            <ol>
              <li>
                <strong>Session Cookies</strong>: these are files that are
                stored on the User's Device and remain there until the User
                leaves the Website or turns off the Internet browser. The stored
                information is then permanently deleted from the Device’s
                memory. The Session Cookies mechanism does not allow the
                collection of any personal data or confidential information from
                the User's Device.
              </li>
              <li>
                <strong>Permanent Cookies</strong>: stored on the User's Device
                and remain there until being deleted. Files of this type remain
                on the User's Device for the time specified in the file
                parameters, or until they are manually deleted by the User.
                Ending the browser session or turning off the Device does not
                delete them from the Device. The Permanent Cookies mechanism
                does not allow the collection of any personal data or
                confidential information from the User's Device.
              </li>
            </ol>
          </li>
          <li>
            Cookies are divided into basic and marketing cookies (criterion: the
            purpose of using Cookies):
            <ol>
              <li>
                <strong>Basic Cookies</strong> - installed after the User’s
                consent through the settings of the software installed on the
                Device. Basic Cookies include:
                <ul>
                  <li>
                    <strong>Technical Cookies</strong> - files necessary to
                    ensure the proper functioning of the Website, including:
                    ensuring the proper display of the Website depending on the
                    Device used by the User or the settings of this Device;
                    adaptation of the content on the Website, having technical
                    significance for the operation of the Website (e.g.
                    language); remembering whether the User has consented to the
                    display of certain content;
                  </li>
                  <li>
                    <strong>Analytical Cookies</strong> - files necessary to
                    measure the effectiveness of marketing activities undertaken
                    by the Administrator without identifying personal data of
                    the Users and to improve the functioning of the Website,
                    including: researching statistics on traffic in the Website
                    and verifying the source of traffic (so-called redirection
                    directions); detection of abuses in website traffic (e.g.
                    artificial internet traffic - bots); measuring the
                    effectiveness of actions conducted for the benefit of the
                    Administrator, e.g. in the Google advertising network, on
                    external websites;
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            The User can delete Cookies from the browser at any time and prevent
            their reinstallation.
          </li>
        </ol>
      </section>
      <section>
        <h2>
          III. WAYS TO DETERMINE THE CONDITIONS OF STORAGE OR ACCESS TO COOKIES;
          <br />
          MANAGEMENT OF COOKIES
        </h2>
        <ol>
          <li>
            The User has the possibility to limit or turn off the access of
            Cookies to the Device. If the User uses this option, using the
            Administrator’s Website will be possible, with the exception of
            functions requiring Cookies by their nature.
          </li>
          <li>
            The User can independently and at any time change the settings
            regarding Cookies, defining the conditions for their storage and
            access by Cookies to the User's Device.
          </li>
          <li>
            Changes to the settings mentioned above, the User may make by using
            browser settings. In particular, these settings can be changed in
            such a way as to block the automatic handling of Cookies in the web
            browser settings or to inform them whenever Cookies are placed on
            the user's Device. Detailed information about the possibility and
            methods of managing Cookie are available in the software settings of
            the web browser.
          </li>
          <li>
            The User can delete Cookies at any time using the available browser
            functions. The User may refuse the collection of Cookies by entering
            the appropriate settings, adequate to the browser being used. In
            order to access the instructions for managing Cookies, the User
            needs to follow the links indicated below:
            <br />
            <br />
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <strong>Chrome</strong>
                  </td>
                  <td colSpan={4}>
                    <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
                      https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <strong>Firefox</strong>
                  </td>
                  <td colSpan={4}>
                    <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">
                      https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <strong>Safari</strong>
                  </td>
                  <td colSpan={4}>
                    <a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac">
                      https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <strong>Opera</strong>
                  </td>
                  <td colSpan={4}>
                    <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
                      https://help.opera.com/en/latest/web-preferences/#cookies
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <strong>Edge</strong>
                  </td>
                  <td colSpan={4}>
                    <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                      https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <strong>Internet Explorer</strong>
                  </td>
                  <td colSpan={4}>
                    <a href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">
                      https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </li>
          <li>
            Limitation of the use of Cookies may affect some of the
            functionalities available on the Administrator's Website.
          </li>
        </ol>
      </section>
      <section>
        <h2>IV. DETAILED INFORMATION ABOUT COOKIES</h2>
        <ol>
          <strong>
            <li>Essential cookies</li>
            <br />
            <h4>
              Cookies necessary for the proper functioning of the Website.
            </h4>
            <br />
          </strong>
          <table>
            <tbody>
              <tr>
                <th>Cookie Name</th>
                <th>Method of operation</th>
                <th>Supplier</th>
                <th>Type / Period of use</th>
              </tr>
              <tr>
                <td>authToken</td>
                <td>
                  It stores a token that allows the User to authenticate on the
                  site and stay logged in.
                </td>
                <td>ratecleaner.com</td>
                <td>permanent (Persistent)</td>
              </tr>
              <tr>
                <td>selected-gds</td>
                <td>
                  It allows you to store the selection of the active GDS when
                  using the website.
                </td>
                <td>ratecleaner.com</td>
                <td>permanent (Persistent)</td>
              </tr>
              <tr>
                <td>menuExpanded</td>
                <td>
                  It stores information about the status of the navigation menu
                  expansion.
                </td>
                <td>ratecleaner.com</td>
                <td>permanent (Persistent)</td>
              </tr>
              <tr>
                <td>acceptCookies</td>
                <td>Accepting essential cookies</td>
                <td>ratecleaner.com</td>
                <td>1 month</td>
              </tr>
              <tr>
                <td>rc::a</td>
                <td>Read and filter requests from bots (reCAPTCHA).</td>
                <td>Google Ireland Limited</td>
                <td>permanent (Persistent)</td>
              </tr>
              <tr>
                <td>rc::c</td>
                <td>Read and filter requests from bots (reCAPTCHA).</td>
                <td>Google Ireland Limited</td>
                <td>permanent (Persistent)</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <strong>
            <li>Analytical cookies</li>
            <br />
            <h4>
              Cookies that allow the Website Administrator to learn how
              different users behave on the website by collecting anonymous
              statistical data.
            </h4>
          </strong>
          <table>
            <tbody>
              <tr>
                <th>Cookie name</th>
                <th>Method of operation</th>
                <th>Supplier</th>
                <th>Type / Period of use</th>
              </tr>
              <tr>
                <td>_ga</td>
                <td>
                  This cookie is used to distinguish unique users by assigning a
                  randomly generated number as a client identifier. It is
                  included in each page request in a site and used to calculate
                  visitor, session and campaign data for the sites analytics
                  reports.
                </td>
                <td>Google Ireland Limited</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>_ga_QYE6ZRVDM7</td>
                <td>
                  This cookie is used by Google Analytics to persist session
                  state.
                </td>
                <td>Google Ireland Limited</td>
                <td>Sessional</td>
              </tr>
            </tbody>
          </table>
        </ol>
      </section>
    </div>
  )
}

export default CookiesPolicy
