import React from "react"

import { getCxFromStyles } from "../../helpers"
import * as styles from "./RateDescription.module.scss"
import starIcon from "../../assets/star.svg"

const AgencyRateDescription: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <div className={cx("list_container")}>
        <span>
          <span className={cx("starbox")}>
            <img src={starIcon} />
            <img src={starIcon} />
            <img src={starIcon} />
          </span>
        </span>
        <span>
          <p className={cx("rate_title")}>
            <strong>Rate Description Verification Tool</strong>
          </p>
          <p className={cx("content")}>
            <strong>Rate Cleaner</strong> offers a comprehensive solution for
            travel agencies to ensure accurate
            <br /> rate descriptions on GDSs and facilitates direct
            communication with hotels over rate
            <br /> issues.
          </p>
        </span>
      </div>
    </div>
  )
}

export default AgencyRateDescription
