import React from "react"

import { getCxFromStyles } from "../../helpers"
import Button from "../Button"
import { LeftSide, RightSide } from "./HotelDetails"

import * as styles from "./Details.module.scss"

import hotelsImage from "../../assets/agency-hotels.png"
import issueImage from "../../assets/agency-issue.png"
import rateImage from "../../assets/agency-rate.png"

const AgencyDetails = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root", "small_padding")}>
      <LeftSide
        cx={cx}
        image={hotelsImage}
        background="hotels"
        title={
          <>
            Verify rates <br />
            in real-time
          </>
        }
        text={
          <>
            Mitigate the risk of booking complications{" "}
            <strong>with real-time GDS rate verification</strong>, ensuring
            discrepancies are caught and corrected promptly.
          </>
        }
        color="green"
      />
      <RightSide
        cx={cx}
        image={issueImage}
        background="issue"
        title={<>Notify hotels directly</>}
        text={
          <>
            <strong>Immediate contact with hotels</strong> is enabled through
            in-app communication, allowing quick rectification of rate
            inaccuracies, streamlining response times, and enhancing accuracy.
          </>
        }
        color="green"
      />
      <LeftSide
        cx={cx}
        image={rateImage}
        background="rate"
        title={
          <>
            Prevent <br />
            booking conflicts
          </>
        }
        text={
          <>
            Proactive identification and resolution of recurrent rate errors
            <strong> prevent future booking issues</strong>, ensuring smoother
            booking operations.
          </>
        }
        color="green"
      />
    </div>
  )
}

export default AgencyDetails
