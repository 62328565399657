import React from "react"

import { getCxFromStyles } from "../../helpers"

import * as styles from "./Benefits.module.scss"

import bellBackground from "../../assets/bell-background.png"
import arrowTrending from "../../assets/arrow-trending-up.svg"
import banknotes from "../../assets/banknotes.svg"
import bolt from "../../assets/bolt.svg"
import chartBarSquare from "../../assets/chart-bar-square.svg"
import buildingOffice from "../../assets/building-office-2.svg"

interface CardProps {
  cx: any
  icon: string
  title: string
  text: string
  color: "blue" | "green"
}

export const Card = ({ cx, icon, title, text, color } : CardProps) => {
  return (
    <div className={cx("card", color)}>
      <div className={cx("icon_container")}>
        <div className={cx("icon_inner", color)}>
          <img className={cx("icon")} src={icon} alt="logoIcon" />
        </div>
      </div>
      <h5>{title}</h5>
      <p>{text}</p>
    </div>
  )
}

const HotelBenefits: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h2>Benefits for Hotels</h2>
      <div className={cx("big_background", "bell_background")}>
        <img src={bellBackground} />
      </div>
      <div className={cx("content")}>
        <Card
          cx={cx}
          icon={arrowTrending}
          title={"Higher rate accuracy"}
          text={
            "Instantly finding and fixing errors to keep your rate descriptions accurate and trustworthy."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={banknotes}
          title={"Reduced operational costs"}
          text={
            "Avoiding the expense and hassle associated with post-booking rate discrepancies and complaints."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={bolt}
          title={"Increased GDS sales"}
          text={
            "Enhancing visibility and sales potential through GDS channels with more accurate rate descriptions."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={chartBarSquare}
          title={"Booking trends insight"}
          text={
            "Track how refined rate descriptions influence GDS bookings to adjust strategies for optimal occupancy."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={buildingOffice}
          title={"Improved competitive edge"}
          text={
            "Gaining a market advantage with rates that accurately reflect the value of your hotel, enticing potential guests over competitors."
          }
          color="blue"
        />
      </div>
    </div>
  )
}

export default HotelBenefits
