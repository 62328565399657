import React from "react"

import { getCxFromStyles } from "../../helpers"
import { Card } from "./HotelBenefits"

import * as styles from "./Benefits.module.scss"

import talkingBackground from "../../assets/talking-background.png"
import arrowTrending from "../../assets/arrow-trending-up.svg"
import banknotes from "../../assets/banknotes.svg"
import bolt from "../../assets/bolt.svg"
import chartBarSquare from "../../assets/chart-bar-square.svg"
import buildingOffice from "../../assets/building-office-2.svg"

const AgencyBenefits: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h2>Benefits for Travel Agencies</h2>
      <div className={cx("big_background", "talking_background")}>
        <img src={talkingBackground} />
      </div>
      <div className={cx("content")}>
        <Card
          cx={cx}
          icon={arrowTrending}
          title={"Decreased booking issues"}
          text={
            "Ensuring accurate rate descriptions leads to fewer booking complications and smoother client interactions."
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={banknotes}
          title={"Enhanced rate accuracy"}
          text={
            "Maintaining the highest level of accuracy for hotel rates by continuously verifying and updating rate information."
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={bolt}
          title={"Increased operational efficiency"}
          text={
            "Streamlining communications with hotel to swiftly address and correct rate discrepancies, improving the booking process."
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={chartBarSquare}
          title={"Stronger hotel partnerships"}
          text={
            "Building trust with hotels through proactive rate issue communication, enhancing service delivery."
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={buildingOffice}
          title={"Increased client satisfaction"}
          text={
            "Assuring clients of accurate bookings, improving trust, and reducing the likelihood of complaints."
          }
          color="green"
        />
      </div>
    </div>
  )
}

export default AgencyBenefits
